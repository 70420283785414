<!-- 数据展示 -->
<template>
    <div class="dataShow">
        <my-Titlebar>
            <template slot="TitlebarLeft">
                <div class="alarmData-titLeft">
                    <!-- <span>数据：</span>
                    <label><span style="font-size: 40px;font-family: 'georgia'">{{total}}</span><span> /条</span></label> -->
                </div>
            </template>
            <template slot="TitlebarRight">
                <div class="alarmData-titRight">
                        <my-btn text="搜索" @click="clickSearchFun"></my-btn>
                    </div>
            </template>
        </my-Titlebar> 
        <div class="dataShow-mins">
            <div class="dataShow-conters-nav" data-aos="fade-right" data-aos-delay="0" @scroll="navScroll">
                <div :class="item.active?'nav-item-active':'dataShow-conters-nav-item'"
                v-for="item,index in list" :key="index" @click="seriesDataFn(item,index)">
                    {{ item.T_name }}
                </div>
            </div>
            <div class="dataShow-conters-table" data-aos="fade-left" data-aos-delay="0">
                <div class="dataShow-conters-tabs">
                    <div class="dataShow-conters-mins">
                        <div  :class="actives==item.label?'nav-active':'dataShow-conters-tabs-item'" v-for="item,index in options" @click="setTabs(item)" :key="index">
                            {{item.label}}
                        </div>
                    </div> 
                    <div class="alarmData-titRight">
                        <my-btn v-show="actives=='查看表格'" text="备份数据" @click="showCopy=true"></my-btn>
                        <my-btn v-show="actives=='查看表格'" text="导出Excel" @click="DowDataExcel"></my-btn>
                        <my-btn v-show="actives=='查看表格'" text="下载PDF" @click="DowDataPDF"></my-btn>
                        <my-btn v-show="actives=='查看图表'" text="截图" @click="canvasIt"></my-btn>
                        <my-btn v-show="actives=='查看图表'" text="下载PDF" @click="downloadPDF"></my-btn>
                        <my-btn v-show="actives=='查看图表'" :text="!showCharts?'显示湿度':'取消显示'" @click="showCharts = !showCharts"></my-btn>
                    </div>
                </div>
                <my-tables ref="tableRef" v-show="actives=='查看表格'" :headers="columns" :requestApi="requestApi"
                :initParam="chartsParam" @checkboxFun="checkboxFun" :indexType="true">
                    <template #T_ist="{ row }">
                        <div>{{row.T_tl}}~{{row.T_tu}}</div>
                    </template>
                    <template #T_ish="{ row }">
                        <div>{{row.T_rhl}}~{{row.T_rhu}}</div>
                    </template>
                </my-tables>
               <div v-if="actives=='查看图表'" :class="showHidden?'showHiddenStyle':''" style="display: flex;flex: 1;" ref="imageTofile">
                    <div style="display: flex;flex-direction: column;width: 100%;height: 100%;padding: 0 50px;overflow-y: auto;">
                        <!-- <h3 style="text-align: center;">州俊勇康药业有限公司</h3>
                        <p style="padding-top: 5px;text-align: center;">2024-04-01 00:00:00~2024-04-01 11:20:08</p> -->
                        <charts
                        style="flex: 1;min-height: 600px;"
                        :seriesData="seriesData"
                        :delSeriesData.sync="delSeriesData"
                        :grainDelect.sync="grainDelect"
                        :issetData="issetData"
                        ></charts>
                        <chartsH
                        v-show="showCharts"
                        style="min-height: 600px;flex: 1;"
                        :seriesData="seriesDataH"
                        :delSeriesDataH.sync="delSeriesDataH"
                        :grainDelectH.sync="grainDelectH"
                        :issetData="issetData"
                        ></chartsH>
                    </div>
               </div>
            </div>
        </div>
        <my-Modalbox :visible.sync="visible" titleBox="下载PDF" iswidth="30%" minHeihts="600px">
            <div slot="modalConter" class="dataShow-model-main">
                <Slides :form.sync="form"></Slides>
                <div class="dataShow-model-btn" @click="onSubmit">
                    <img src="../../assets/img/btn.png">
                    <label>立即提交 </label>
                </div>
            </div>
        </my-Modalbox>
        <!-- 备份数据 -->
        <my-Modalbox :visible.sync="showCopy" titleBox="备份数据" iswidth="70%" isheight="200px">
            <div slot="modalConter" class="dataShow-model-showCopy">
                <backupCopy :list="list"></backupCopy>
            </div>
        </my-Modalbox>
        
        <Drawers ref="drawersRef" titles="搜索" @searchFun="searchFun" :drawer.sync="drawer"></Drawers>
    </div>
</template>

<script>
import { DataList,DevSenList,DataChartPdf } from "@/api/dataDisplay/chartShow"
import html2canvas from "html2canvas";
import Slides from './Slides.vue'
import charts from "./charts.vue";
import chartsH from "./chartsH.vue";
import backupCopy from "./backupCopy.vue";


import Drawers from "./Drawers.vue";
import { formatDate } from '@/utils/Times.js'
import { DataExcel, DataPDF} from '@/api/dataDisplay/dataShow.js'
import Axios from 'axios'
import fileDownload from 'js-file-download';
export default {
    components:{charts,chartsH,Drawers,Slides,backupCopy},
    data() {
        return {
            showHidden:true,
            showCharts:false,//显示温湿度
            showCopy:false,//备份数据
            form:{//下载pdf
                T_forwarding_unit:'',
                T_consignee_unit:'',
                T_template:'0',
                T_remark:'',
            },
            drawer:false,
            visible:false,
            list:[],
            seriesDataH:{},//湿度
            seriesData:{},//温度
            delSeriesData:'',
            delSeriesDataH:'',
            grainDelect:false,//彻底删除
            grainDelectH:false,//彻底删除
            issetData:null,//原始数据
            actives:'查看图表',
            requestApi: DataList,
            columns: [
                { key: 'T_name', label: '传感器名称',width:120},
                { key: 'T_t', label: '温度',width:120},
                { key: 'T_ist', label: '温度范围',name: 'T_ist' ,width:120},
                { key: 'T_rh', label: '湿度',width:120},
                { key: 'T_ish', label: '湿度范围',name: 'T_ish',width:120},
                { key: 'T_time', label: '记录时间',width:200},
                { key: 'T_remark', label: '备注'},
            ],
            options: [{
                value: '选项2',
                label: '查看图表'
            },{
                value: '选项1',
                label: '查看表格'
            }],
            chartsMenu:{
                T_name: '',
                T_calssid:  '',
                page: 1,
                page_z: 20,
            },
            navsize:0,//总分页
            total:0,
            chartsParam:{
                // T_snid: '2023474781642913,1|',
                T_snid: '2023474781642913,1|',
                Time_start: '2024-04-04 00:00:00',
                Time_end: '2024-04-04 23:14:43',
                page: 1,
                page_z: 99999
            },
        }
    },
    mounted() {
        this.getDevSenListApi()
        this.setTimefn()
    },
    methods:{
        async navScroll(e){
            // let Hitbottom = e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight
            let isBottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight-1
            console.log('滚动',isBottom,this.chartsMenu.page,this.navsize)
            if (isBottom && this.chartsMenu.page < this.navsize) {
                let height = e.target.scrollTop
                e.target.scrollTop = height
                this.chartsMenu.page++
                await this.getDevSenListApi()
            }else if(isBottom && this.chartsMenu.page>this.navsize){
                this.$message.info('已经到底了')
                return
            }
        },
        /***************************备份数据 */
        backupCopy(){

        },

        onSubmit(){//提交下载pdf
            this.showHidden = false
            setTimeout(async () => {
                this.loading = this.$loading({
                    lock: true,
                    text: '请稍后...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                const url = await this.html2canvasFun()
                this.showHidden = true
                DataChartPdf({
                    T_snid:this.chartsParam.T_snid,
                    Time_start:this.chartsParam.Time_start,
                    Time_end:this.chartsParam.Time_end,
                    file:url.split(',')[1],
                    T_temp_show:1,
                    T_Humidity_show:0,//显示湿度传1，隐藏传0
                    ...this.form
                }).then(res => {
                    var arr1 = res.data.Data.split('/')
                    this.loading.close()
                    this.dialogVisible = false
                    Axios({
                        method: 'get',
                        url: res.data.Data,
                        responseType: 'blob'
                    }).then(resBlob => {
                        const FileSaver = require('file-saver')
                        const blob = new Blob([resBlob.data], {
                            type: 'application/pdf;charset=utf-8'
                        }) // 此处type根据你想要的
                        FileSaver.saveAs(blob, arr1[arr1.length-1]) // 下载的name文件名
                        this.visible = false
                    });
                })    
            }, 10);
        },
        DowDataExcel() {//表格下载Excel
				////console.log('导出exc', this.pages1)
				if (this.chartsParam.T_snid == "") {
					this.$message.error('请选择设备在导出数据')
					return
				} else {
					const loading = this.$loading({
						lock: true,
						text: '正在整理数据，请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					DataExcel(this.chartsParam).then(res => {
						////console.log('导出exc', res)
						var arr1 = res.data.Data.split('/')
						loading.close()
						Axios({
							method: 'get',
							url: res.data.Data,
							responseType: 'blob'
						}).then(resBlob => {
							fileDownload(resBlob.data, arr1[arr1.length-1]);
						});
					})
				}
			},
        DowDataPDF() {//表格下载pdf
				if (this.chartsParam.T_snid == "") {
					this.$message.error('请选择设备在导出数据')
				} else {
					const loading = this.$loading({
						lock: true,
						text: '正在整理数据，请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					DataPDF(this.chartsParam).then(res => {
						console.log('下载',res)
						var arr1 = res.data.Data.split('/')
						loading.close()
						Axios({
							method: 'get',
							url: res.data.Data,
							responseType: 'blob'
						}).then(resBlob => {
							const FileSaver = require('file-saver')
							const blob = new Blob([resBlob.data], {
								type: 'application/pdf;charset=utf-8'
							}) // 此处type根据你想要的
							FileSaver.saveAs(blob, arr1[arr1.length-1]) // 下载的name文件名
						});
					})
				}
			},
        setTimefn() { //获取当天0点与当前时间 
			const now = new Date();  
			const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0); 
			const min = midnight.getTime()//今天0点时间戳，
			const max = now.getTime(); //当前时间戳
			this.chartsParam.Time_start = formatDate(min)
			this.chartsParam.Time_end = formatDate(max)

            this.$refs.drawersRef.TimeData = [formatDate(min),formatDate(max)]
			this.$emit("pick", this.$refs.drawersRef.TimeData)
		}, 
        clickSearchFun(){
            this.drawer = true
        },
        async searchFun(data){
            this.chartsMenu.T_name = data.T_name//menu的name
            this.chartsMenu.T_calssid = data.T_calssid//menu的
            this.chartsMenu.page = 1
            this.list = []
            await this.getDevSenListApi()

            this.chartsParam.Time_start = data.Time_start
            this.chartsParam.Time_end = data.Time_end
            this.grainDelect = true //彻底删除温度
            this.grainDelectH = true //彻底删除湿度
            console.log('彻底删除',this.$refs.tableRef)
            this.$refs.tableRef.tableData = []
        },

        /*************************截图 */
        canvasIt() {//截图
            this.showHidden = false
            setTimeout(async () => {
                const url = await this.html2canvasFun()
                console.log('返回',url)
                var a = document.createElement('a')
                a.download = new Date().getTime()
                a.href = url;// 设置图片地址
                a.click();
                this.showHidden = true
            }, 10);
        },
        html2canvasFun(){
            return new Promise(resolve=>{
                html2canvas(this.$refs.imageTofile, {
                    backgroundColor:' #fff',
                }).then((canvas) => {
                    resolve(canvas.toDataURL("image/png"))
                });
            })
        },
        /*************************截图结束 */

        /*************************下载pdf */
        async downloadPDF(){
            const reslut = this.list.some(obj => obj.active === true);
            if (!reslut) {
                this.$message.error('请选择设备') 
                return;
            }else{
                this.visible = true
            }
            
        },
        /*************************下载pdf结束 */

        async getDevSenListApi(){
            let reslut = await DevSenList(this.chartsMenu)
            this.total = reslut.data.Data.Num
            this.navsize = reslut.data.Data.Page_size
            let arr = reslut.data.Data.DeviceSensor_lite || []
            arr.forEach(item=>{
                item.active = false
            })
            this.list = [...this.list,...arr]
        },
        async seriesDataFn(item,index){
            // console.log('点击选择',item)
            let arr = [...this.list]
            if(this.actives=='查看图表'){//当前查看图表
                if(this.list[index].active){//取消
                    this.list[index].active = false
                    this.delSeriesData = item.T_name
                    this.delSeriesDataH = item.T_name
                }else{//选择
                    this.chartsParam.T_snid = item.T_sn + ',' + item.T_id  + '|'
                    let reslut = await DataList(this.chartsParam)
                    let arrayData = reslut.data.Data.Data?reslut.data.Data.Data.reverse() || []:[]
                    let arrData = []
                    this.issetData = arrayData//原始数据
                    let arrDataH = []//湿度
                    if(arrayData.length>0){
                        arrayData.forEach(obj => {
                            arrData.push([Date.parse(obj.T_time),obj.T_t])
                            arrDataH.push([Date.parse(obj.T_time),obj.T_rh])
                            // arrData.push([new Date(obj.T_time).getTime(),obj.T_t])
                        });
                        this.seriesData = {  
                            name: item.T_name,  
                            lineWidth:3, // 设置曲线宽度为 5 
                            data:arrData,
                            turboThreshold: 10000 // 根据需要设置更大的阈值
                        }
                        this.seriesDataH = {//湿度  
                            name: item.T_name,  
                            lineWidth:3, // 设置曲线宽度为 5 
                            data:arrDataH,
                            turboThreshold: 10000 // 根据需要设置更大的阈值
                        }
                        
                        this.list[index].active = true
                    }else{
                        this.$message.info(`【${item.T_name}】没有可展示数据`)
                        return
                    }
                }
            }else{//当前查看表格
                this.list[index].active = !this.list[index].active
                let mapArr = []
                const arrData = arr.filter(item => item.active == true)//哪些选中的
                arrData.forEach(element => {
                   if(element.active) mapArr.push(element.T_sn + ',' + element.T_id)
                });
                this.chartsParam.T_snid = mapArr.join('|')
                this.$refs.tableRef.callPropFunction()
                console.log('表格',this.$refs.tableRef)
            }
        },
        setTabs(item){
            this.chartsParam.page = 1
            this.actives = item.label
            if (item.label=='查看表格') {
                let arrData = [...this.list]
                let arr = []
                arrData.forEach(element => {
                   if(element.active) arr.push(element.T_sn + ',' + element.T_id)
                });
                this.chartsParam.T_snid = arr.join('|')
                this.chartsParam.page_z = 10
                this.$refs.tableRef.callPropFunction()
                this.grainDelect = true //彻底删除
                this.grainDelectH = true //彻底删除
            }else{
                this.grainDelect = false //彻底删除
                this.grainDelectH = false //彻底删除
                let arr = [...this.list]
                this.chartsParam.page_z = 99999
                const arrData = arr.filter(item => item.active == true)//哪些选中的
                arrData.forEach(async(element) => {
                    this.chartsParam.T_snid = element.T_sn + ',' + element.T_id  + '|'
                    let reslut = await DataList(this.chartsParam)
                    let arrayData = reslut.data.Data.Data?reslut.data.Data.Data.reverse() || []:[]
                    let arrDataT = []
                    let arrDataH = []//湿度
                    if(arrayData.length>0){
                        arrayData.forEach(obj => {
                            arrDataT.push([Date.parse(obj.T_time),obj.T_t])
                            arrDataH.push([Date.parse(obj.T_time),obj.T_rh])
                        });
                        this.seriesData = {  
                            name: element.T_name,  
                            lineWidth:3, // 设置曲线宽度为 5 
                            data:arrDataT,
                            turboThreshold: 10000 // 根据需要设置更大的阈值
                        }
                        this.seriesDataH = {  
                            name: element.T_name,  
                            lineWidth:3, // 设置曲线宽度为 5 
                            data:arrDataH,
                            turboThreshold: 10000 // 根据需要设置更大的阈值
                        }
                        
                    }else{
                        this.$message.info(`【${element.T_name}】没有可展示数据`)
                        return
                    }
                });
            }
        },
        checkboxFun(){}
    }
}
</script>
<style scoped>
::v-deep .el-tabs__item {
    color: #5fc5ff;
}

::v-deep .el-tabs__item.is-active {
    color: #5fc5ff !important;
}

::v-deep .el-tabs__nav-wrap::after {
    background-color: #183d7d;
}

::v-deep .el-form-item__label {
    color: #5fc5ff;
}

::v-deep .el-input__inner {
    background-color: #183d7d;
    border-radius: 4px;
    border: 1px solid #183d7d;
    color: #fff;
}

::v-deep .el-switch.is-checked .el-switch__core {
    border-color: #183d7d;
    background-color: #183d7d;
}

::v-deep .el-switch__core {
    border: 1px solid #f56c6c;
    background: #f56c6c;
}

::v-deep .el-radio {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    margin-right: 30px;
}
</style>
<style lang="scss">
@import url('../../assets/scss/dataShow.scss');
</style>